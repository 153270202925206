import "./NFT.css";
import { Button, Card, Row, Col, Table } from "react-bootstrap";
import React, { useState, useEffect, } from "react";
import { Link, useParams } from "react-router-dom";
import { onError } from "../lib/errorLib";
import { API, Storage } from "aws-amplify";
import Form from "react-bootstrap/Form";
import LoaderButton from "../components/LoaderButton";
import { useFormFields } from "../lib/hooksLib";
import algosdk from "algosdk";
import config from "../config";
import { useAppContext } from "../lib/contextLib";
import Footer from "./Footer";

export default function NFT() {
    const [nfts, setnfts] = useState([]);
    const { id, nft } = useParams();
    const [availQty, setavailQty] = useState("0");
    const { currentOrgObj } = useAppContext();
    const [isLoading, setIsLoading] = useState(true);
    const [showTransfer, setshowTransfer] = useState(false);
    const [showTransferLoading, setshowTransferLoading] = useState(false);
    const [prcsTransfer, setprcsTransfer] = useState(false);
    const [dispatcherWalletAddress, setdispatcherWalletAddress] = useState();
    const [displayInfo, setdisplayInfo] = useState(false);
    const [dispMsg, setdispMsg] = useState([]);
    const [errormsgFormat, seterrormsgFormat] = useState(true);

    const [fields, handleFieldChange] = useFormFields({
        destAddress: ""
    });

    async function handleSubmit(event) {
        event.preventDefault();

        setshowTransferLoading(true);
        setdisplayInfo(false);
        setprcsTransfer(false);
        setdisplayInfo(false);
        let amount = (fields.destAddress.match(/,/g) || []).length + 1;

        if (amount > availQty) {
            seterrormsgFormat(true);
            setdispMsg("Error: Please make sure there are enough tokens available to send!!");
            setdisplayInfo(true);
            setshowTransferLoading(false);
            return;

        }
        if (fields.destAddress.length === 0) {
            seterrormsgFormat(true);
            setdispMsg("Error: Please enter a valid email address!!");
            setdisplayInfo(true);
            setshowTransferLoading(false);
            return;

        }

        var emails = fields.destAddress.replace(/\s/g, '').split(",");
        var valid = true;
        var regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        for (var i = 0; i < emails.length; i++) {
            if (emails[i] === "" || !regex.test(emails[i])) {
                valid = false;
            }
        }
        if (!valid) {
            seterrormsgFormat(true);
            setdispMsg("Error: Please enter valid email addresses");
            setdisplayInfo(true);
            setshowTransferLoading(false);
            return;

        }


        let ret = await transferNFT(dispatcherWalletAddress, nfts, amount);
        if (ret) {
            await initiateTransfer(fields, nfts);
            setprcsTransfer(true);
        }

        setIsLoading(false);
        setshowTransfer(false);
        //setprcsTransfer(true);
        setshowTransferLoading(false);
    }

    async function initiateTransfer(fields, nft) {


        const tranObj = {
            emailAddresses: fields.destAddress,
            nft: `${nft[0].sk}`,
            assetID: `${nft[0].assetID}`
        }

        return API.post("mygiving", "/initiateTransfer", {
            body: tranObj
        });


    }

    async function initiateRevoke() {

        const pk = "ORG#"+id;
        const sk = "NFT#"+nft;
        const tranObj = {
            pk:pk,
            sk: sk           
        }

        return API.post("mygiving", "/revokeNFT", {
            body: tranObj
        });


    }



    const Results = () => (
        <div id="results" className="alert alert-success   padding-lg " role="alert">
            <span>Transfer Initiated Successfully</span>
        </div>
    );

    async function transferNFT(dispatcherWalletAddress, nfts, amount) {

        if(nfts[0].assetOwner===dispatcherWalletAddress){
            return true;
        }
        //console.log(nfts, parseInt(nfts[0].assetID));
        var assetIDInt = parseInt(nfts[0].assetID);
        //const algodClient = new algosdk.Algodv2('', 'https://testnet.algoexplorerapi.io/', '');
        const algodClient = new algosdk.Algodv2('', config.ALGO_EXP_API, '');
        /*let txParams = await algodClient.getTransactionParams().do();
        txParams.fee = 1000;
        txParams.flatFee = true;*/

        try {
            await window.AlgoSigner.connect({
                ledger: config.LEDGER
            });
            const accts = await window.AlgoSigner.accounts({
                ledger: config.LEDGER
            });
            /*const txParams = await window.AlgoSigner.algod({
                ledger: 'TestNet',
                path: '/v2/transactions/params'
            });
*/
            let assetID = assetIDInt;
            let params = await algodClient.getTransactionParams().do();
            let sender = accts[0]['address'];
            console.log("Sender:" + sender, nfts[0].assetOwner, nfts);
            if (sender !== nfts[0].assetOwner) {
                seterrormsgFormat(true);
                setdispMsg("Error: Please make sure you are connected to the wallet that created this asset");
                setdisplayInfo(true);
                setshowTransferLoading(false);
                return false;
            }
            let recipient = dispatcherWalletAddress;
            let revocationTarget = undefined;
            let closeRemainderTo = undefined;
            let note = undefined;
            let txn = algosdk.makeAssetTransferTxnWithSuggestedParams(sender, recipient, closeRemainderTo, revocationTarget,
                amount, note, assetID, params);

            // Use the AlgoSigner encoding library to make the transactions base64
            let txn_b64 = window.AlgoSigner.encoding.msgpackToBase64(txn.toByte());

            let signedTxs = await window.AlgoSigner.signTxn([{ txn: txn_b64 }]);
            /*                .then((d) => {
                                signedTxs = d;
                            })
                            .catch((e) => {
                                console.error(e);
                            });
            */
            /*const signedTx = await window.AlgoSigner.signTxn({
                assetIndex: assetIDInt,
                from: accts[0]['address'],
                to: dispatcherWalletAddress,
                amount: amount,
                type: 'axfer',
                fee: txParams['min-fee'],
                firstRound: txParams['last-round'],
                lastRound: txParams['last-round'] + 1000,
                genesisID: txParams['genesis-id'],
                genesisHash: txParams['genesis-hash'],
                flatFee: true
            })*/

            await window.AlgoSigner.send({
                ledger: config.LEDGER,
                tx: signedTxs[0].blob
            })
            //console.log(r);
            return true;
        }
        catch (e) {
            console.log(e);
            //alert(JSON.stringify(e, null, 2));
            //console.log(JSON.stringify(e, null, 2));
            //console.log(JSON.stringify(signedTx, null, 2));
            seterrormsgFormat(true);
            setdispMsg("Error:" + e.message);
            setdisplayInfo(true);
            setshowTransferLoading(false);
            return false;
        }

    }
    const DisplayInfo = () => (
        <div>
            {errormsgFormat ?
                <div id="dispInfo" className="alert alert-danger   padding-lg " role="alert">
                    <span>{dispMsg}</span>
                </div>
                :
                <div id="dispInfo" className="alert alert-success   padding-lg " role="alert">
                    <span>{dispMsg}</span>
                </div>
            }
        </div>
    );

    useEffect(() => {
        async function onLoad() {

            try {
                var nftsDetails = await API.get("mygiving", `/organization/${id}/NFTS/${nft}`);
                var nfts = nftsDetails['nft'];
                //Object.entries(nfts).forEach(async (nft)=>{nft.imageURL=await Storage.vault.get(nft.imageURL)});
                for (var i = 0; i <= nfts.length - 1; i++) {
                    if (nfts[i].imageURL) {
                        nfts[i].imageURL = await Storage.get(nfts[i].imageURL, { level: 'public' });
                    }

                }
                //nfts[4].imageURL = await Storage.vault.get(nfts[4].imageURL);
                setnfts(nfts);
                setavailQty(nftsDetails['assetHolders'].totalQty);

                let orgDispatcher = await API.get("mygiving", `/organization/${id}`);
                setdispatcherWalletAddress(orgDispatcher.dispatcherWalletAddress);
                //console.log("SenderAddress:" + orgDispatcher.dispatcherWalletAddress);


            } catch (e) {
                onError(e);
            }

            setIsLoading(false);

        }
        onLoad();
    }, [id, nft]);

    /*
    function loadNfts() {
        return API.get("mygiving", `/organization/${id}/NFTS`);
    }
*/



    /*   const Results = () => (
           <div id="results" className="justify-content-md-center results ">
               <Form onSubmit={handleSubmit}>
                   <Form.Group controlId="destAddress" size="lg">
                       <Form.Label>Enter destination ALGO address. Please make sure the receipent has already Opted in</Form.Label>
                       <Form.Control
                           autoFocus
                           type="text"
                           onChange={handleFieldChange}
                           value={fields.destAddress}
                       />
                   </Form.Group>
                   <LoaderButton
                       block
                       size="lg"
                       type="submit"
                       variant="success"
                       isLoading={isLoading}
                   >
                       Transfer
                   </LoaderButton>
               </Form>
           </div>
       );
   
   */
    const onClick = () => {
        setprcsTransfer(false);
        setshowTransfer(true);
        setdisplayInfo(false);
    }

    const linkTag = (assetID) => { return (<><a target="_blank" rel="noreferrer" href={config.PURESTAKE_API + "/asset/" + assetID}>{assetID}</a></>) };

    function rendernftsList(nfts) {
        return (
            <>
                <Row xs={1}   className="g-4 justify-content-md-center">
                    {nfts.map(({ sk, description, imageURL, name, qty, descrShort, tokenAddress, tokenChain, createdAt, assetID }) => (
                        <Col key={sk}>
                            <Card className="NFTListItem-main-card mx-auto"   >
                                <div className="imageHolder mx-auto">
                                    <Card.Img className="OrgListItem-img mx-auto mt-1" src={imageURL} />
                                </div>
                                <Card.Body>
                                    <Card.Title className=" fw-bold text-bold text-center">{name}</Card.Title>
                                    <Card.Text className="small text-center">
                                        Qty : {availQty}/{qty}

                                    </Card.Text>
                                </Card.Body>
                                <Card.Footer className="justify-content-md-center" >
                                    <div className="justify-content-md-center" >
                                        <Button style={{ 'backgroundColor': currentOrgObj.orgColorTheme }} onClick={onClick}>Initiate Transfer</Button>
                                    </div>
                                </Card.Footer>

                            </Card>
                            <div className="mt-3 text-center mx-auto justify-content-md-center">
                            <div className="justify-content-md-center" >
                                        <Button className="revoke" onClick={initiateRevoke}>REVOKE</Button>
                                    </div>
                            </div>
                            <br />                            
                            <div className="mt-3 text-center mx-auto justify-content-md-center">
                                <Link to={`/org/${id}/admin/nfts/${nft}/history`}>
                                    <h6 className="text-center">Transfer History</h6>
                                </Link>
                            </div>
                            <br />
                            <Table className="tableDiv">

                                <tbody>
                                    <tr>
                                        <td>Name</td>
                                        <td className="fw-bold">{name}</td>
                                    </tr>
                                    <tr>
                                        <td>Description</td>
                                        <td className="fw-bold">{description}</td>
                                    </tr>
                                    <tr>
                                        <td>Short Description</td>
                                        <td className="fw-bold">{descrShort}</td>
                                    </tr>
                                    <tr>
                                        <td>Total Quantity </td>
                                        <td className="fw-bold"> {qty}</td>
                                    </tr>
                                    <tr>
                                        <td>Available Quantity </td>
                                        <td className="fw-bold"> {availQty}</td>
                                    </tr>
                                    <tr>
                                        <td>Asset Details</td>
                                        <td className="fw-bold"> {linkTag(assetID)}</td>
                                    </tr>

                                </tbody>
                            </Table>

                        </Col>


                    ))}

                </Row>

            </>
        );
    }



    function rendernfts() {
        return (
            <div className="nfts">
                <h2 className="pb-3 mt-4 mb-3 border-bottom">{id} Digital Asset Collections</h2>
                {!isLoading && rendernftsList(nfts)}
            </div>
        );
    }

    return (
        <div className="Home">

            {rendernfts()}
            <div>

                {showTransfer ?
                    <div id="results" className="justify-content-md-center results ">
                        <Form onSubmit={handleSubmit}>

                            <Form.Group controlId="destAddress" size="lg" >
                                <Form.Label>Enter email Addresses (separated by a comma)</Form.Label>
                                <Form.Control
                                    autoFocus
                                    as="textarea"
                                    onChange={handleFieldChange}
                                    value={fields.destAddress}
                                />
                            </Form.Group>
                            <LoaderButton
                                block
                                size="lg"
                                type="submit"
                                isLoading={showTransferLoading}
                                style={{ 'background-color': currentOrgObj.orgColorTheme }}
                            >
                                Transfer
                            </LoaderButton>
                        </Form>
                    </div>
                    : null}
            </div>
            <div>

                {prcsTransfer ? <Results /> : null}
            </div>
            <br />
            <div>

                {displayInfo ? <DisplayInfo /> : null}
            </div>
            {!isLoading ? <Footer />:null}
        </div>
    );
}
