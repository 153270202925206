import "./Wallets.css";
import { Card, Row, Col, Button } from "react-bootstrap";
import React, { useState, useEffect, } from "react";
import { useParams, Link } from "react-router-dom";
import { onError } from "../lib/errorLib";
import { API, Storage } from "aws-amplify";
import { LinkedinShareButton, TwitterShareButton, FacebookShareButton } from "react-share";
import { FacebookIcon, LinkedinIcon, TwitterIcon } from "react-share";
import config from "../config";
import Footer from "./Footer";



export default function Wallets() {



  //const [nfts, setnfts] = useState([]);
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [groupedbyOrg, setgroupedbyOrg] = useState([]);

  let walletAddress = window.location.pathname.split("/wallets/")[1];
  console.log("WalletAddress" + walletAddress);




  useEffect(() => {
    async function onLoad() {

      try {

        var nfts = await API.get("mygiving", `/wallets/${id}`);
        //console.log(nfts);
        //Object.entries(nfts).forEach(async (nft)=>{nft.imageURL=await Storage.vault.get(nft.imageURL)});
        for (var i = 0; i <= nfts.length - 1; i++) {
          if (nfts[i].imageURL) {
            nfts[i].imageURL = await Storage.get(nfts[i].imageURL, { level: 'public' });
          }

        }
        //nfts[4].imageURL = await Storage.vault.get(nfts[4].imageURL);
        setIsLoading(false);
        //nfts=sort_by_key(nfts, 'pk');
        //setnfts(nfts);
        setgroupedbyOrg(groupBy(nfts, 'pk'));

        /*    const url = `https://testnet.algoexplorerapi.io/v2/accounts/${walletAddress}`;

            const data = await window.fetch(url, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
            })
             .then((response) => response.json())
            .then((data) => {
                if (data) {
                     
                        console.log("Wallet Balance" + JSON.stringify(data,2));
                     
                }
            });
 
            // console.log("Wallet Data"+JSON.stringify(await data.json(),2));
            //setwalletAdd(data);
            setwalletData(await data.json());
*/

      } catch (e) {
        onError(e);
      }



    }
    onLoad();
  }, [walletAddress, id]);

  /*function flip() {
    try {
    document.querySelector('#flip-toggle').classList.toggle('hover');
  } catch (e) {
    console.log(e);
  }
  }*/

  function flip2() {
    try {
      document.querySelector('#flip-toggle').classList.toggle('flip');
    } catch (e) {
      console.log(e);
    }

  }



  const linkTag = (assetID) => { return (<><a target="_blank" rel="noreferrer" href={config.PURESTAKE_API + "/asset/" + assetID}>{assetID}</a></>) };

  function rendernftsList(nfts) {   

    return (
      <>
        <hr/>
        <h6 className="mt-3"><a target="_blank" rel="noreferrer" href={window.location.origin +"/org/"+nfts[0].pk.split("#")[1]}>{nfts[0].pk.split("#")[1]} Collection</a></h6>
        <Row xs={1}   className="g-4 mx-auto justify-content-md-center mt-5" key={nfts[0].pk}>
          {nfts.map(({ pk, sk, description, imageURL, name, qty, tokenAddress, tokenChain, createdAt, assetID }) => (
            <>

              <Col className="justify-content-md-center mx-auto" key={assetID}>
                <div className="flip-container" onClick={flip2}>
                  <div className="flipper flip-toggle">
                    <div className="front">


                      <Card className="WalletListItem-main-card "   >
                        <Card.Img className="WalletListItem-img mx-auto justify-content-md-center" src={imageURL} />
                      </Card>
                    </div>
                    <div className="back ">
                      <Card className="WalletListItem-main-card "   >
                        <div className="mx-auto justify-content-md-center fw-bold ">
                          <p className="justify-content-md-center">{name}</p>
                          <p>Organization: {pk.split("#")[1]}</p>
                          <p className="mx-auto justify-content-md-center">Description: {description}</p>                          
                          <p className="mx-auto justify-content-md-center">Asset Details: {linkTag(assetID)} </p>
                        </div>
                      </Card>
                    </div>
                  </div>
                </div>
                <div className=" d-flex p-2 mx-auto justify-content-md-center justify-content-center">
                  <Link to={`/org/${pk.split("#")[1]}/nfts/${sk.split("#")[1]}`}>
                    <Button className="primary d-flex p-2">Details</Button>
                  </Link>
                </div>
              </Col>
            </>

          ))}

        </Row>
       

      </>
    );
  }
  var groupBy = function (xs, key) {
    return xs.reduce(function (rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  };

  /*function sort_by_key(array, key) {
    return array.sort(function (a, b) {
      var x = a[key]; var y = b[key];
      return ((x < y) ? -1 : ((x > y) ? 1 : 0));
    });
  }*/

  function rendernfts() {
    return (
      <div className="nfts">
        <div className="mt-3 mb-4">
          <h4 className="pb-3 mt-4 mb-3 border-bottom">{id.length > 50 ? id.substring(0, 5) + "..." + id.substring(id.length - 5) : id} NFT Collection
          </h4>
          <div>
            <FacebookShareButton className="px-2" url={window.location} title="See my Donation NFT/FTs collection here...">
              <FacebookIcon round size={32} />
            </FacebookShareButton>
            <TwitterShareButton className="px-2" url={window.location} title="See my Donation NFT/FTs collection here...">
              <TwitterIcon round size={32} />
            </TwitterShareButton>
            <LinkedinShareButton className="px-2" url={window.location} title="See my Donation NFT/FTs collection here...">
              <LinkedinIcon round size={32} />
            </LinkedinShareButton>
          </div>


        </div>
         
          {!isLoading &&
            Object.keys(groupedbyOrg).map((Org) => (rendernftsList(groupedbyOrg[Org])))
          }
         
      </div>
    );
  }



  return (
    <div className="Home container">

      <div>
        {rendernfts()}

      </div>
      {!isLoading?<Footer /> :null}

    </div>
  );
}